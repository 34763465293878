import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import Prompt from '../src/prompt';
import Resume from '../src/pages/resume';
import Header from '../src/components/header';
import './App.scss';

function Layout() {
  return (
  <>
    <Header />
    <Outlet />
  </>
  );
}

export default function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [  
        {
          path: '/',
          element: <Prompt />
        },
        {
          path: '/resume',
          element: <Resume />
        },
      ]
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
