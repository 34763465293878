import React, {useState} from 'react';
import './resume.scss';

export function Resume() {
  document.title = "Jason Coffman | Resume";
  const googleSkills =  ['Angular', 'Typescript', 'Kotlin', 'D3', 'data visualization', 'gRPC'];
  const awsSkills =  ['React', 'Typescript', 'Java', 'AWS Lambda', 'AWS CloudFormation'];
  const baaSkills =  ['React', 'Typescript', 'Python', 'Amazon DynamoDB'];
  const experience = new Date().getFullYear() - 2017;
  return (
    <div className='resume'>
      <div className='carousel'> Full-stack engineer with {experience}+ years of experience</div>
      <h2>Professional Experience</h2>
      <div className='role'>
        Software Engineer @ Google <br/> 
        December 2021 - Present 
        <div className='skills'>
        {googleSkills.map((skill) => (
          <SkillChip key={skill} skill={skill}></SkillChip>
        ))}
      </div>
      </div>
      <div className='role'>Front-End Architect @ Amazon Web Services <br/>
      March 2019 - December 2021
      <div className='skills'>
        {awsSkills.map((skill) => (
          <SkillChip key={skill} skill={skill}></SkillChip>
        ))}
      </div>
      </div>
      <div className='role'>Staff Engineer @ Berkeley Applied Analytics <br/> March 2017 - March 2019
      <div className='skills'>
        {baaSkills.map((skill) => (
          <SkillChip key={skill} skill={skill}></SkillChip>
        ))}
      </div>
      </div>
    </div>
  );
}

function SkillChip(props: any) {
  return (
    <div className='chip'>{props.skill}</div>
  )
}

export default Resume;
