import React, {useState} from 'react';

import * as webllm from "@mlc-ai/web-llm";
import './messages.scss';

export default function DialogPrompt(props: any) {
  const [modelReady, setModelReady] = useState(false);
  const [messages, setMessages] = useState<Array<string>>([])

  const setLabel = (id: string, text: string) => {
    const label = document.getElementById(id);
    if (label == null) {
      throw Error("Cannot find label " + id);
    }
    label.innerText = text;
  }

  const initModel = async () => {
    const initProgressCallback = (report: webllm.InitProgressReport) => {
      setLabel("init-label", report.text);
    };

    const selectedModel = "Llama-3-8B-Instruct-q4f32_1";
    const engine: webllm.EngineInterface = await webllm.CreateEngine(
      selectedModel,
      { initProgressCallback: initProgressCallback }
    );
    const reply0 = await engine.chat.completions.create({
      messages: [
        { "role": "user", "content": "List three US states." },
      ],
      // below configurations are all optional
      n: 3,
      temperature: 1.5,
      max_gen_len: 256,
      // 46510 and 7188 are "California", and 8421 and 51325 are "Texas" in Llama-3-8B-Instruct
      // So we would have a higher chance of seeing the latter two, but never the first in the answer
      logit_bias: {
        "46510": -100,
        "7188": -100,
        "8421": 5,
        "51325": 5,
      },
      logprobs: true,
      top_logprobs: 2,
    });
    console.log(reply0);
    console.log(await engine.runtimeStatsText());
  }

  const handleSubmit = (evt: any) => {
    if (evt.key && evt.key === 'Enter') {
      setMessages([...messages, evt.target.value])
      evt.target.value = ''
    }
  }

  return (
    <div className={`prompt-dialog ${props.isOpen ? "open" : ""}`}>
      <h3>Coming soon...</h3>
      <span id="init-label"></span>
      <div className="prompt-body">
        {messages.map((message) => (
          <div className="msg-entry">
            <span className="icon"></span>
            <span>{message}</span>
          </div>
        ))}
      </div>
      <input className="space-mono-regular" placeholder="Ask here!" onKeyDown={handleSubmit}></input>
    </div>
  )
}