import React from 'react';
import { Link, useLocation } from "react-router-dom";

import './header.scss';

function Header() {
  const location = useLocation();
  const {pathname } = location;

  return (
    <div className={pathname === '/' ? 'llamaHeader' : 'header'}>
        {pathname == '/' ? <></> : <Link  to='/'>Home</Link>}
        <Link to='/resume'>Resume</Link>
        <a target='_blank' href="https://www.linkedin.com/in/jtcoffman/">LinkedIn</a>
        <a target='_blank' href="https://github.com/jasoncoffman">GitHub</a>
    </div>
  );
}

export default Header;