import React, {useState} from 'react';
import { useSearchParams } from 'react-router-dom'; 
import DialogPrompt from './components/messages';
import './prompt.scss';

export function Prompt() {
  document.title = "Jason Coffman | Home";

  const [openDialog, setOpenDialog] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const mods = searchParams.get('mods')
  const activateLlama = mods?.includes('llama')

  if (activateLlama) {
    return (
      <div>
        <header className="HeroContainer">
        <div>
          <p className={`${showResume ? "dissolveUp" : ""}`}>Hello, I'm Jason</p>
          <p className={`hidden ${showResume ? "revealIn" : ""}`}>Software Engineer @ Google</p>
          <p className={`caption hidden ${showResume ? "revealIn" : ""}`}>December 2021 - Present</p>
        </div>
      </header>
        <button onClick={() => setShowResume(true)} className="floating-action-button nav-anchor" aria-hidden="true">Resume</button>
        <div className="floating-action-button desktop-only" role="button" onClick={() => setOpenDialog(!openDialog)} tabIndex={1}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M240-400h320v-80H240v80Zm0-120h480v-80H240v80Zm0-120h480v-80H240v80ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"/></svg>
        </div>
        <DialogPrompt isOpen={openDialog}></DialogPrompt>
      </div>
    ); 
  } else {
    return (
      <div>
        <header className="HeroContainer">
          <div>
            <p className={`mainTitle ${showResume ? "dissolveUp" : ""}`}>Hello, I'm Jason</p>
            <p className={`bodyText hidden ${showResume ? "revealIn" : ""}`}>Software Engineer @ Google</p>
            <p className={`bodyText caption hidden ${showResume ? "revealIn" : ""}`}>Building the future of Google Search with <a href="https://labs.google.com/search" target="_blank">Search Labs</a></p>
            <p className={`bodyText caption hidden ${showResume ? "revealIn" : ""}`}>December 2021 - Present</p>
          </div>
        </header>
        <button onClick={() => setShowResume(true)} className={`floating-action-button nav-anchor ${showResume ? "hidden" : ""}`} aria-hidden="true">Learn more</button>
      </div>
    );
  } 
}

export default Prompt;
